.section-store-locator {
  .store-locator__links-container {
    .store-locator__dutyfree {
      display: none;
    }
  }
}
/* Live Chat */
.site-header {
  .lpStaticButton {
    width: auto;
    margin: 0px;
    td {
      border: none;
      padding: 0px;
    }
    .lpPoweredBy,
    .lpEmtStarRating {
      display: none;
    }
    a img {
      position: relative;
    }
  }
}

.search-results__header--desktop {
  #perlgem-search-form {
    .search-form__fields {
      input#search {
        padding-right: 100px;
      }
    }
  }
}

.customer-service-section {
  #landing-call {
    border-bottom: 1px solid #d1d2d1;
    border-right: 1px solid $color-gray-alt;
  }
  #landing-faqs {
    border-right: 1px solid #d1d2d1;
    border-top: none;
  }
  .customer-service-landing-block {
    height: 350px;
    bottom: 0px;
  }
  #landing-email {
    background: none;
    border-bottom: 1px solid #d1d2d1;
  }
  .customer-service-contact-form {
    .customer-service-contact-form__category {
      padding: 2em 20px;
    }
  }
  .customer-service-landing-block__link {
    bottom: 75px;
  }
}

.spp {
  .product--full {
    .product__unit-price {
      display: none;
    }
  }
}

.spp-product-layout {
  .sticky-add-to-bag {
    &__shade-dot {
      @include breakpoint($medium-portrait-only) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &__ctas-wrapper--non-shaded {
      @include breakpoint($medium-portrait-only) {
        width: 50%;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .customer-service-section {
    .customer-service-landing-block {
      height: 400px;
    }
  }
}

.quickshop__container {
  .product__details {
    .ask__answer {
      display: none;
    }
  }
}

.customer-service {
  .customer-service-quick-info {
    .customer-service-quick-info__list-item {
      margin-left: 12%;
    }
  }
}

.product__header {
  #BVQASummaryContainer {
    clear: left;
  }
}

.site-nav {
  .menu__link--lvl-2 {
    @include krfont-bold();
  }
  .menu__link--lvl-1 {
    @include krfont-bold();
  }
}

.cart_overlay {
  .cart-confirm-wrapper {
    .cart-products {
      .currency_type {
        display: none;
      }
    }
  }
}

.trustmark-logo {
  width: 100px;
}

.customer-service-contact-form__country-select.dropdown::-ms-expand {
  display: block;
}

.order-details,
.order-confirmation {
  .order-totals {
    th {
      padding-top: 5px;
      padding-bottom: 0.72em;
    }
  }
}

.order-details {
  .order-products {
    .cart-header {
      .products {
        width: 18%;
      }
      .cart-header-sub-container {
        width: 78%;
      }
    }
  }
}

.makeup-lessons-item {
  &__lesson {
    &.is-video {
      .makeup-lessons-item__lesson-button {
        @include breakpoint($tablet) {
          background-position: 9px 9px;
        }
      }
    }
  }
}

.search-form input[type='submit'] {
  &.search-form__submit {
    line-height: 1px;
  }
}

.icon-pinterest {
  display: none;
}

.site-footer__column {
  position: relative;
  .site-footer-social-links {
    position: absolute;
    .site-footer-social-links__item {
      position: relative;
      .icon-pinterest {
        display: inline-block;
      }
      .icon-pinterest:before,
      .icon-google:before {
        display: none;
      }
      a.icon.icon-pinterest {
        background-image: url('/media/images/global/Kakao_black.jpg');
        height: 22px;
        width: 22px;
        &:hover {
          background-image: url('/media/images/global/Kakao_pink.jpg');
          height: 22px;
          width: 22px;
        }
      }
      /* a.icon.icon-kakaoplus {
        background-image: url("/media/images/global/Kakaoplus_black.jpg");
        height: 24px;
        width: 24px;
        &:hover {
          background-image: url("/media/images/global/Kakaoplus_pink.jpg");
          height: 24px;
          width: 24px;
        }
      } */
      a.icon.icon-google {
        background-image: url('/media/images/global/Naver_black.jpg');
        height: 22px;
        width: 22px;
        &:hover {
          background-image: url('/media/images/global/Naver_pink.jpg');
          height: 22px;
          width: 22px;
        }
      }
      &:nth-child(2) {
        left: 2px;
      }
      &:nth-child(3) {
        left: 99px;
      }
      &:nth-child(4) {
        left: -10px;
        margin-bottom: 30px;
      }
      &:nth-child(5) {
        left: -114px;
      }
    }
  }
}

.ie8 .site-utils__promo {
  height: 25px;
  line-height: 30px;
}

.icon-twitter {
  display: none;
}

.long_wear_eye_collection {
  overflow: hidden;
}
/* Container */
#colorbox {
  &.disconnect-overlay-launch {
    height: 180px !important;
    top: 1300px !important;
  }
  &.zdc_popup {
    height: 380px !important;
  }
  #cboxContent {
    #cboxLoadedContent {
      #address_form_container {
        input {
          display: block;
          float: none;
          font-family: inherit;
          font-size: inherit;
          height: 40px;
          margin: 0 15px 10px 0;
          position: relative;
          text-transform: inherit;
        }
        select {
          display: block;
          float: none;
          font-family: inherit;
          font-size: inherit;
          height: 40px;
          margin: 5px 15px 10px 0;
          position: relative;
          text-transform: inherit;
        }
        .adpl {
          input[type='email'],
          input[type='password'],
          input[type='tel'],
          input[type='text'],
          select {
            & + label {
              margin-top: calc(-3.75em - 1px);
              height: 3em;
              line-height: 4em;
            }
          }
          input[type='tel'] {
            margin-top: 5px;
          }
          select {
            & + a.selectBox {
              margin-top: 5px;
              line-height: 2.75;
            }
          }
        }
        .address-form__item.form_element.radio.default_ship_container {
          label {
            .label-content {
              display: inline-block;
              margin-bottom: 5px;
            }
          }
        }
        .selectBox-dropdown {
          height: 40px;
        }
        #profile-postal {
          width: auto;
          margin-bottom: 1em;
          .fieldset-note {
            vertical-align: middle;
            display: inline-block;
            width: 32%;
            margin-left: 5px;
            padding-left: 5px;
            top: 2px;
          }
          .address_lookup_submit {
            width: 100%;
          }
          width: 100%;
        }
        input.input_postal_code {
          display: inline;
          margin: 5px 0 0 0;
          vertical-align: middle;
        }
        input.input_default {
          display: inline;
        }
        fieldset.fs {
          div.form_element.radio {
            input {
              display: inline;
              margin: 0;
              width: 0;
              height: 0;
            }
          }
        }
        .required-message {
          clear: both;
          margin-bottom: 10px;
        }
        .address-form__item {
          padding: 0;
          clear: both;
        }
        #address-form {
          float: left;
          width: 270px;
          fieldset.address {
            div.phone_part {
              margin-top: 5px;
            }
          }
          h2 {
            margin: 2px 0 2px 0;
            font-size: 20px !important;
          }
          width: 100%;
          padding: 10px 10px 10px 10px;
        }
        h2 {
          float: left;
          clear: both;
        }
        fieldset.fs {
          .full_name_container {
            float: none;
          }
          .address1_container {
            float: none;
            clear: both;
          }
          .address2_container {
            float: none;
          }
          .address3_container {
            float: none;
          }
          div.phone_part_area_code {
            select {
              width: 100% !important;
              float: left;
            }
            width: 35%;
            margin-top: 5px;
          }
          div.form_element {
            input {
              width: 100%;
            }
            margin: 0 0 0 0;
            width: 100%;
          }
          width: 100%;
          margin: 5px 0 5px 0;
          padding: 0 0 0 0;
        }
        fieldset.address {
          div.phone_part {
            input {
              width: 90% !important;
            }
            margin-right: 5px !important;
          }
          #profile-postal {
            input {
              width: 65%;
            }
            width: 100%;
          }
        }
        .submit_btn {
          margin: 0 10px 0 0;
        }
        .error_messages {
          width: 70%;
          clear: both;
          color: #000;
        }
        .grey-button {
          display: block;
          text-align: center;
          width: auto;
          background-color: #999;
          color: #fff;
          cursor: pointer;
          text-decoration: none;
          text-transform: uppercase;
          font-weight: bold;
          height: 40px;
          line-height: 1;
          margin: 5px 0 3px 0;
        }
        font-size: 11px;
        padding: 0;
        #address-lookup-container {
          .rna-list-header {
            display: none;
            text-align: center;
            font-weight: bold;
            font-size: 13px;
            li {
              width: 99.5%;
              display: block;
              float: left;
              border: 1px solid #f6f0e6;
              &:first {
                background-color: #ddd9c3;
              }
            }
            header1 {
              width: 73.7%;
              float: left;
              text-align: center;
              padding: 10px 0 10px 0;
            }
            header2 {
              float: left;
              text-align: center;
              padding: 10px 20px 10px 20px;
              border-left: 1px solid #f6f0e6;
            }
          }
          #rna-loading {
            width: 60px;
            margin: 0 auto 0 auto;
            display: none;
          }
          .address {
            a.town-submit-btn {
              padding: 9px 18px;
            }
            padding: 5px 5px 5px 5px;
            border: none;
          }
          .address-search {
            border-bottom: 1px solid #000;
            color: #000;
            margin: 0 5px 0 5px;
            padding: 0 0 5px 0;
            width: 97%;
            #rna_keyword {
              float: left;
              width: 80%;
              margin: 0 5px 0 0;
            }
            h4 {
              font-size: 20px;
              font-weight: bold;
              margin-bottom: 10px;
            }
            .keyword-hint {
              padding: 5px 0;
              font-weight: normal;
              clear: both;
              border: none;
            }
            h3 {
              font-size: 12px;
              border: none;
              margin: 0;
            }
          }
          #rna-page-list {
            float: left;
            margin: 0 0 0 0;
            padding: 10px 0 10px 0;
            width: 100%;
            text-align: center;
          }
          .error-msg {
            display: none;
            margin: 0 auto 0 auto;
            width: 35%;
            padding: 30px 0 30px 0;
          }
          .error-display {
            width: 95%;
            background-color: #f7f7f7;
            margin: 10px 10px 10px 10px;
          }
          .error_messages.error {
            margin: 5px 0 0 10px;
            padding: 20px;
            text-align: center;
            width: 90%;
            display: none;
          }
          .address1 {
            display: block;
            padding: 5px 0 5px 0;
            cursor: pointer;
          }
          .address3 {
            cursor: pointer;
          }
          .rna-list {
            display: none;
            border: none;
            margin: 0 0 0 0;
            width: 100%;
            tbody {
              max-height: 215px;
              overflow-y: auto;
              overflow-x: hidden;
              display: inline-block;
            }
            .sno {
              width: 14px;
            }
            th.full-address {
              text-align: center;
            }
            .full-address {
              width: 500px;
            }
            .postal {
              width: 115px;
            }
            th {
              line-height: 15px;
              height: 15px;
            }
            tr {
              border-top: 1px solid #ccc;
              border-bottom: 1px solid #ccc;
              display: block;
            }
            .roadAddrPart1 {
              display: none;
            }
            .roadAddrPart2 {
              display: none;
            }
          }
          #address-hint-container {
            h4 {
              padding-left: 10px;
              font-weight: bold;
              margin: 10px 0 5px 0;
              color: #000;
            }
            background-color: #fff;
            padding-top: 5px;
            .hint-table {
              width: 95%;
              border-collapse: collapse;
              margin: 0 10px 0 10px;
              border-top: 1px solid #ccc;
              border-bottom: 1px solid #ccc;
              padding: 10px 10px 10px 10px;
              font-size: 10px;
              .hint-table-header {
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                padding: 10px 10px 10px 10px;
                font-size: 10px;
                background-color: #f7f7f7;
              }
              .hint-table-data {
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                padding: 10px 10px 10px 10px;
                font-size: 10px;
              }
            }
            .building-name-registered {
              padding: 10px 10px 10px 10px;
              margin: 0 0 0 0;
            }
          }
          #address-results-container {
            background-color: #fff;
            padding-top: 5px;
            display: none;
            .rna-list {
              width: 100%;
              border-collapse: collapse;
              margin: 0 10px 0 0;
              border: none !important;
              border-top: 1px solid #ccc;
              border-bottom: 1px solid #ccc;
              padding: 0 10px 10px 10px;
              font-size: 10px;
              th {
                border: none !important;
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                padding: 10px 10px 10px 10px;
                font-size: 10px;
                background-color: #f7f7f7;
              }
              td {
                border: none !important;
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                padding: 10px 10px 10px 10px;
                font-size: 10px;
              }
            }
            zipcode {
              float: right;
            }
            .search-results-header {
              padding-left: 10px;
              color: #000;
              font-weight: bold;
              margin-left: 0;
            }
          }
          #address-third-elements {
            .zip {
              display: none;
            }
          }
          #address-input-container {
            h4 {
              font-size: 14px;
              padding: 0 0 5px 10px;
              color: #000;
              font-weight: bold;
            }
            label {
              display: block;
              float: left;
              width: 20%;
              font-weight: bold;
            }
            .building {
              margin: 0 auto 0 auto;
              width: 57%;
              .green-btn.small1.check-balance {
                margin: 10px 55px 10px 55px;
                float: left;
              }
            }
            ra3 {
              padding: 5px 0 5px 0;
              display: block;
            }
            .address-search-h {
              margin: 5px 0 5px 0;
            }
            #addr2_input_node {
              width: 75%;
            }
            .street {
              margin: 10px 10px 10px 10px;
            }
            .detail-address {
              margin: 10px 10px 10px 10px;
            }
            .address-details {
              background-color: #f7f7f7;
              padding: 10px 10px 10px 10px;
              margin: 0 10px 0 10px;
            }
            .address-submit-button {
              padding: 10px 10px 10px 10px;
              width: 100%;
              .address-input-submit {
                margin: 0 auto 0 auto;
                width: 50%;
                height: 30px;
                line-height: 14px;
              }
            }
          }
        }
      }
      #address-confirmation {
        padding: 10px;
        width: 100%;
        text-align: center;
        h2 {
          font-size: 16px;
          color: #000;
          margin: 0 0 10px 0;
          font-weight: bold;
        }
        .address-confirm-buttons {
          margin: 20px 0 0 55px;
          .address-confirm {
            width: 35%;
            float: left;
            .update-confirm {
              width: 100%;
            }
          }
          .address-cancel {
            float: left;
            width: 35%;
            margin-left: 5px;
            .grey-button {
              font-family: 'HelveticaNeueLTStd65Medium', Verdana, Arial, Sans-Serif;
              background-color: #999;
              text-transform: uppercase;
              color: #ccc;
              line-height: 1.5;
              font-size: 0.9em;
              text-align: center;
              letter-spacing: 0;
              border: 0;
              padding: 4px 10px 3px 10px;
              display: inline-block;
              zoom: 1;
            }
            .update-cancel {
              height: 32px;
              width: 100%;
            }
          }
        }
        .change-address-content {
          width: 100%;
          padding: 10px 0 10px 0;
          line-height: 18px;
          margin: 0 auto 0 auto;
        }
      }
    }
    #cboxClose {
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
}

#return_confirm {
  margin: 40px auto;
  overflow: hidden;
  max-width: 1024px;
  footer.site-footer {
    clear: both;
    padding: 10px 0;
  }
  #sidebar {
    float: left;
    margin-right: 32px;
  }
}

#address_form_container {
  .fs .form_element {
    margin: 10px 0;
  }
}

.sign-in-page,
.profile-page {
  .pc_email_promo,
  .sms_promo,
  .postal_promo,
  .promotions_label {
    .label.error,
    .label_error {
      color: red;
    }
  }
}

body.section-glow-guide {
  article.product_guide_dropdown {
    overflow: hidden;
  }
}

.site-footer {
  &-bottom {
    &__trademark {
      @include breakpoint($medium-up) {
        line-height: 20px;
      }
    }
  }
}

.site-footer-social-links {
  .site-footer-social-links__menu {
    .icon {
      line-height: 1;
    }
  }
}

.content {
  .sticky-offer {
    background: #ff4661 !important;
  }
}

.tiny-waitlist-overlay {
  .field {
    border-top: 1px solid gray;
    padding-top: 10px;
    .align-r {
      display: inline-block;
    }
  }
  .email_input {
    display: inline-block;
    input.error {
      border: 1px solid red;
    }
  }
  .waitlist-header {
    display: block;
    font-weight: bold;
    padding-bottom: 10px;
  }
  .privacy_text {
    .label.error {
      color: red;
    }
  }
}

.waitlist_thankyou_message {
  margin: 90px 60px;
  font-family: 'Brandon Text Bold';
}
/* Power reviews */
#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-side-content-block {
          width: 210px;
        }
        .pr-rd-footer {
          .pr-rd-helpful-action {
            .pr-helpful-yes {
              .pr-helpful-count:before {
                content: '예';
              }
            }
            .pr-helpful-no {
              .pr-helpful-count:before {
                content: '아니오';
              }
            }
          }
        }
      }
    }
  }
}
/******************
*  media queries  *
*******************/

/* MOBILE */
@media all and (max-width: 764px) {
  header.site-header {
    height: 54px !important;
  }
  .site-content > .block {
    position: relative !important;
  }
  .game-title h2 {
    display: inline-block;
    margin-bottom: 0 !important;
  }
  #roulette-wrapper h2 {
    font-size: 24px !important;
  }
  #roulette-wrapper > #roulette-copy-button-container.mobile-message {
    position: absolute;
    background: #fff;
    z-index: 4;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    border: 8px solid #ff4661;
  }
  #roulette-wrapper .message {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    display: table-cell;
  }
}

@media all and (max-width: 990px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none !important;
  }
  #roulette-wrapper {
    width: 100%;
    padding-bottom: 20px;
  }
  #roulette-wrapper > div {
    width: 100%;
    display: block;
    position: relative;
  }
  #roulette-wrapper > #roulette-wheel-container {
    margin-bottom: -130px;
    margin-top: 20px;
  }
  #roulette-wrapper > #roulette-wheel-container > #roulette-pointer {
    top: -45px;
    left: 50%;
    margin-left: -20px;
  }
  #roulette-wrapper > #roulette-copy-button-container {
    padding: 0;
    margin-bottom: 30px;
  }
  #roulette-wrapper .game-rules.mobile {
    background: #fff;
    margin-top: 60px;
    padding-top: 45px;
    min-height: 250px;
  }
  #roulette-wrapper .message .game-rules {
    margin-top: 20px;
  }
  #roulette-wrapper > #roulette-copy-button-container {
    padding: 20px;
  }
  .terms p {
    width: 90%;
    max-width: none;
  }
  #roulette-game-loading h2 {
    margin-top: 40px;
  }
  #roulette-wrapper > #roulette-wheel-container > .roulette-wheel {
    top: -30px;
  }
  #roulette-wrapper > #roulette-wheel-container > .roulette-wheel > img {
    width: 100%;
    max-width: 500px;
  }
}

@media all and (min-width: 764px) {
  #roulette-wrapper h2.first-line-title {
    font-size: 70px;
    margin-top: 10px;
  }
  #roulette-wrapper h2.second-line-title {
    font-size: 60px;
  }
}
/* DESKTOP */
@media all and (min-width: 990px) {
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
  #roulette-wrapper {
    width: 100%;
    margin: 60px 0 80px 0;
    height: 458px;
    min-width: 990px;
    max-width: 1024px;
    border: 4px solid #ff4661;
  }
  #roulette-wrapper .main-copy p,
  #roulette-wrapper #spin-again-message p,
  #roulette-wrapper .game-unavailable p {
    font-size: 18px;
    line-height: 1.45em;
  }
  #roulette-wrapper .main-copy p,
  #roulette-wrapper #spin-again-message p {
    height: 90px;
    padding: 10px 0 20px 0;
  }
  #roulette-wrapper > div {
    width: 50%;
    float: left;
    height: 100%;
  }
  #roulette-wrapper .message .game-rules {
    bottom: 0;
  }
  #roulette-wrapper .message .game-rules,
  #roulette-wrapper > #roulette-copy-button-container .game-rules.desktop {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
  }
  #roulette-wrapper > #roulette-copy-button-container .game-rules.desktop {
    bottom: 0;
  }
  #roulette-wrapper > #roulette-wheel-container > #roulette-pointer {
    top: -50px;
    left: 196px;
  }
  #roulette-wrapper > #roulette-copy-button-container {
    padding: 20px;
  }
  #roulette-wrapper > #roulette-copy-button-container > .game-unavailable:not(.hide),
  #roulette-wrapper > #roulette-copy-button-container > #roulette-game-loading {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
    height: 100%;
  }
  #roulette-wrapper > #roulette-wheel-container > .roulette-wheel {
    width: 540px;
    height: 540px;
    margin-top: -40px;
    margin-left: -50px;
  }
  #roulette-wrapper p {
    width: 400px;
  }
  .terms p {
    width: 80% !important;
    max-width: 600px;
  }
  #roulette-wrapper h2 {
    font-size: 40px;
  }
}

#roulette-wrapper p {
  margin: 10px auto;
}

#roulette-wrapper .win-msg-product-copy p {
  margin: 5px auto !important;
}

#roulette-wrapper button {
  padding: 10px;
  width: 200px;
  margin: 10px 0;
  background: #ff4661;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 3;
  height: auto;
  line-height: 14px;
}

#roulette-wrapper h2.prize {
  margin-top: 60px;
}

#roulette-wrapper h2 {
  color: #c1100a;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 35px;
  font-family: 'Lato Black';
  margin-bottom: 20px;
}

#roulette-wrapper {
  text-align: center;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

#roulette-wrapper > #roulette-wheel-container {
  background: #ff4661;
}

#roulette-wrapper > div {
  box-sizing: border-box;
  position: relative;
}

#roulette-wrapper > #roulette-copy-button-container {
  display: table;
}

#roulette-wrapper > #roulette-copy-button-container p {
  margin-left: auto;
  margin-right: auto;
}

#roulette-wrapper > .message p {
  line-height: 20px;
}

.terms p {
  font-size: 11px;
  line-height: 14px !important;
  margin-top: 20px;
}

#roulette-wrapper p.spin-remaining,
#roulette-wrapper p.spins-remaining {
  color: #ff4661;
  font-size: 14px;
  text-transform: uppercase;
}

#roulette-wrapper .play-button.disabled {
  background: #ccc;
  cursor: default;
}

#roulette-wrapper .product-image {
  height: 120px;
  width: auto;
  display: block;
  margin: 0 auto;
}

@-webkit-keyframes left-right {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-25deg);
    -o-transform: rotate(-25deg);
    transform: rotate(-25deg);
  }
}

@keyframes left-right {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(-25deg);
    -moz-transform: rotate(-25deg);
    -webkit-transform: rotate(-25deg);
    -o-transform: rotate(-25deg);
    transform: rotate(-25deg);
  }
}

#roulette-wrapper > #roulette-wheel-container > #roulette-pointer {
  z-index: 2;
  position: absolute;
}

#roulette-wrapper > #roulette-wheel-container > #roulette-pointer.left-right {
  -webkit-animation: left-right 0.2s;
  -moz-animation: left-right 0.2s;
  -ms-animation: left-right 0.2s;
  -o-animation: left-right 0.2s;
  animation: left-right 0.2s;
  -webkit-animation-iteration-count: 18;
  animation-iteration-count: 18;
  -webkit-animation-timing-function: ease-out; /* Safari 4.0 - 8.0 */
  animation-timing-function: ease-out;
}

#roulette-wrapper > #roulette-wheel-container > .roulette-wheel {
  z-index: 1;
  position: relative;
  -ms-transform: rotate(35.6deg);
  -webkit-transform: rotate(35.6deg);
  transform: rotate(35.6deg);
}

#roulette-wrapper > #roulette-wheel-container > .roulette-wheel.spin_animate {
  -webkit-transition: -webkit-transform 4s ease-out;
  -moz-transition: -moz-transform 4s ease-out;
  -o-transition: -o-transform 4s ease-out;
  transition: transform 4s ease-out;
}

/******************
* FIRST SPIN WINS *
*******************/

#roulette-wrapper
  > #roulette-wheel-container
  > .roulette-wheel:not(.spin_again):not(.spin_again_2).roulette_set {
  -webkit-transform: rotate(1009.6deg);
  -moz-transform: rotate(1009.6deg);
  transform: rotate(1009.6deg) !important;
}

#roulette-wrapper
  > #roulette-wheel-container
  > .roulette-wheel:not(.spin_again):not(.spin_again_2).roulette_product2 {
  -webkit-transform: rotate(938deg);
  -moz-transform: rotate(938deg);
  transform: rotate(938deg) !important;
}

#roulette-wrapper
  > #roulette-wheel-container
  > .roulette-wheel:not(.spin_again):not(.spin_again_2).roulette_product {
  -webkit-transform: rotate(1152.6deg);
  -moz-transform: rotate(1152.6deg);
  transform: rotate(1152.6deg) !important;
}

#roulette-wrapper
  > #roulette-wheel-container
  > .roulette-wheel:not(.spin_again):not(.spin_again_2).roulette_set2 {
  -webkit-transform: rotate(1080deg);
  -moz-transform: rotate(1080deg);
  transform: rotate(1080deg) !important;
}

#roulette-wrapper
  > #roulette-wheel-container
  > .roulette-wheel:not(.spin_again):not(.spin_again_2).roulette_product3 {
  -webkit-transform: rotate(938deg);
  -moz-transform: rotate(938deg);
  transform: rotate(868deg) !important;
}

.primer-plus {
  .block_plus:after {
    height: 200px;
  }
  .block_plus {
    .product-image-qs .product__price .price {
      font-weight: normal;
    }
    .product-image-qs__product .product-image-qs__product-name {
      font-weight: normal;
      top: 332px;
    }
    .product-collection-product__product .product-collection-product__product-name {
      font-weight: normal;
      top: 331px;
      .product_collection__header {
        font-size: 18px;
      }
      .product-collection-product__price {
        top: 71px;
      }
    }
  }
  .block_product {
    .product-collection-product__product-name {
      line-height: 1.5em;
    }
  }
}

.adpl {
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='text'],
  select {
    & + label {
      margin-top: calc(-3.5em - -3px);
    }
    &:required + label:before {
      content: '* ' attr(placeholder);
    }
  }
}

.cart_overlay {
  .cart-confirm-wrapper {
    .cart-products {
      .prod {
        .qty {
          font-size: 16px;
        }
      }
    }
  }
}

.book-appt-container {
  .registration__terms {
    &.error {
      color: $color-red;
    }
  }
}

.site-footer__wrap {
  z-index: 100;
}

.hero-content-block {
  .content-block {
    &__picture {
      img {
        @include breakpoint($large-up) {
          max-height: 690px;
        }
      }
    }
  }
}

textarea {
  &.error {
    border: 1px solid $color-red;
  }
}
