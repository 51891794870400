.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

select::-ms-expand {
  display: none;
}

.margin_top_bottom {
  margin: 0.8em 0;
}

.cart_overlay {
  .cart-confirm-wrapper {
    .cart-products {
      .prod {
        padding: 17px 0;
      }
    }
  }
}

.sign-in {
  .registration {
    &__email {
      width: 100%;
    }
  }
}

.search-results__header {
  .search-form {
    &__results-count {
      line-height: 35px;
      #{$rdirection}: 181px;
    }
    input[type=submit].search-form__submit {
      margin-#{$ldirection}: 0;
    }
  }
}

.site-footer__wrap {
  .site-footer {
    .site-footer {
      &__column {
        width: 28%;
        padding: 0 15px;
        .site-footer__social-links {
          .site-footer-social-links__menu {
            flex-wrap: unset;
          }
        }
        &:last-child {
          width: 16%;
          padding: 0 0 0 20px;
        }
        .site-email-signup__form {
          input[type='submit'] {
            padding: 0 8px;
          }
        }
      }
    }
  }
}

.customer-service-section {
  div {
    display: block;
  }
}

.lp_buttons_area {
  .lp_submit_button,
  .lp_confirm_button,
  .lp_close_survey_button {
    &:hover {
      background: $color-black !important;
    }
  }
}

.mantle-custom-text {
  span,
  p {
    font-family: $bb-sans-n !important;
  }
}

.gnav-section {
  &__link-wrapper {
    &__inner {
      font-family: $bb-sans-n !important;
    }
  }
  &__link {
    font-family: $bb-sans-n !important;
  }
}

.tabbed-products-block {
  &__tab {
    font-family: $bb-sans-n !important;
  }
}

.shade-finder-block {
  &__filter {
    font-family: $bb-sans-n !important;
  }
}
