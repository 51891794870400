$ab-ipad: (width 350px 780px);

.appt-book {
  .appointment-booking-main-collection__header {
    .appointment-booking-main-collection__header-content {
      height: auto !important;
    }
  }
  #appointment-book-sections {
    .appointment-booking-review__account-signup-form {
      .signup__mobile-prefix {
        width: 60px !important;
      }
      .signup__mobile-phone {
        padding-left: 55px !important;
      }
    }
    .js-no-last-name {
      .signup__appt_mobile_part1 {
        width: 39%;
      }
      .signup__appt_mobile_part2 {
        width: 39%;
      }
    }
  }
}

.appointments-page.account__section {
  .appt-book-no-appts-content {
    @include breakpoint($ab-ipad) {
      .mobile-hidden {
        display: block !important;
        width: 70% !important;
      }
    }
    .no-appts-content {
      width: 55% !important;
    }
  }
  padding-left: 2% !important;
  padding-right: 2% !important;
}
