.checkout {
  .offer_code_form_container {
    input[type='text'] {
      & + label {
        margin-top: calc(-3.25em - -2px);
      }
    }
    #one-offer-only {
      margin: 10px 0;
    }
  }
  .adpl {
    .delivery-info {
      input[type='text'] {
        margin-bottom: 4px;
      }
    }
  }
  .checkout-index-header {
    & + ul.error {
      li {
        margin: 10px 0;
      }
    }
  }
  .virtual-products-table {
    .thumb {
      width: 16%;
    }
    .name {
      width: 31%;
    }
    .price {
      width: 21%;
    }
    .qty {
      width: 14%;
    }
    .total {
      text-align: right;
      width: 18%;
    }
  }
  ol.checkout-progress {
    li {
      float: left;
      margin-left: 1.5em;
      width: 12.5em;
      list-style-type: decimal;
      margin-top: 1.5em;
    }
  }
  .submit {
    text-align: right;
    margin-bottom: 1.5em;
  }
  &__content.left {
    .disabled.continue-checkout {
      background-color: $color-gray-border;
    }
    .cart-items .sub {
      &__total {
        float: right;
        margin: 0.8em 0;
      }
    }
    .cart-items .cart-item {
      &__qty {
        width: 16%;
      }
      &__total {
        width: 22%;
      }
    }
    .shipping-page-info-panel {
      .address-info {
        float: left;
        margin: 0 20px 10px 0;
        width: 30%;
        word-wrap: break-word;
        &.address_error {
          width: auto;
          color: $color-red;
        }
      }
      .address_controls {
        float: left;
        width: 65%;
      }
      #address_form_container {
        position: relative;
        border: 0;
        .full_name_container,
        .address1_container {
          margin-bottom: 15px;
          input[type='text'] {
            margin-bottom: 0px;
          }
        }
        #profile-postal {
          width: 50%;
          .input_postal_code {
            width: 100%;
            float: left;
          }
          input[type='text'] + label {
            float: left;
            width: 60%;
          }
          .fieldset-note {
            left: 100%;
          }
        }
      }
    }
    .trans_detail_item {
      float: left;
      margin-bottom: 25px;
      margin-right: 10px;
      position: relative;
      width: 210px;
      word-wrap: break-word;
      h4 {
        border-bottom: 1px solid $color-gray-border;
        font-weight: bold;
        margin-bottom: 10px;
        padding-bottom: 5px;
        text-transform: none;
      }
      .change_link {
        position: absolute;
        right: 0;
        top: 5px;
      }
      &#delivery-options {
        width: 170px;
        margin-right: 10px;
      }
      &#gift-options {
        margin-right: 0;
      }
      #gift_info {
        display: none;
      }
      .gift-order {
        #gift_info {
          display: block;
        }
        #no-gift-info {
          display: none;
        }
      }
    }
  }
  &__sidebar.right {
    #links-panel {
      .links_list li {
        border: 0;
        display: block;
      }
    }
    .custome-service-panel__title {
      @include krfont-bold();
    }
    .order-summary-panel__title {
      @include krfont-bold();
    }
    .links-panel__title {
      @include krfont-bold();
    }
    .offer-code-panel__title {
      @include krfont-bold();
    }
  }
  //Control directive to highlight checkout breadcrumb
  $checkout: (shipping, review, billing, confirm);
  @each $value in $checkout {
    &.#{$value} {
      .checkout-progress__#{$value} {
        font-weight: bold;
      }
    }
  }
  .cross_sell_container {
    .cart_cross_sell_item {
      float: left;
      width: 33%;
      box-sizing: border-box;
      padding-right: 2em;
      margin-bottom: 20px;
      margin-right: 0px;
      .description {
        margin-bottom: 15px;
      }
      .color {
        margin-bottom: 14px;
      }
    }
  }
  .continue-buttons {
    .disabled {
      background-color: $color-gray-border;
    }
    a.disabled:hover {
      background-color: $color-gray-border;
    }
  }
  .shipping-panel__title {
    @include krfont-bold();
  }
  .checkout__panel-title {
    @include krfont-bold();
  }
  .shipping-info-panel__title {
    @include krfont-bold();
  }
  .viewcart-panel__title {
    @include krfont-bold();
  }
  .signin-to-see-cart a {
    @include krfont-bold();
    color: $color-red;
  }
}

.order-receipt {
  .order-products {
    .cart-item__desc-container {
      width: 90%;
      .cart-item {
        padding-top: 20px;
        &__thumb {
          width: 80px;
          margin-right: 10px;
        }
        &__desc {
          float: left;
          width: 42%;
          margin-left: 10px;
        }
        &__qty {
          float: left;
          clear: none;
          padding-top: 0;
          margin: 0 10px;
          width: 16%;
        }
        &__total {
          width: 13%;
          float: left;
          line-height: 36px;
          text-align: left;
        }
        &__price {
          width: 24%;
        }
      }
    }
    .cart-item .order-totals {
      float: right;
      width: 29%;
      tr,
      th {
        border: 0;
      }
      td {
        border: 0;
        padding: 0;
      }
    }
  }
}

.address-form {
  ul.error_messages {
    li {
      color: $color-red;
    }
  }
}

.samples-page {
  .product {
    min-height: 445px;
    .sample-select-checkbox {
      display: block !important;
      visibility: visible;
    }
    .sample-select-button {
      display: block !important;
    }
    &.first {
      margin-left: 0;
    }
  }
  #samples-panel {
    .samples-buttons.bottom {
      display: block;
    }
  }
  .samples-panel__title {
    @include krfont-bold();
  }
}

.cart-header {
  .qty {
    width: 25%;
  }
  .total {
    width: 12%;
  }
}

#delivery-info-panel {
  #delivery-instructions {
    padding: 20px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .checkout {
    section.panel {
      padding-left: 10px;
    }
  }
}

.checkout__content.left {
  .trans_detail_item {
    width: 290px;
    margin-right: 25px;
  }
  .trans_detail_item#delivery-options {
    width: 290px;
  }
  #gift-options {
    margin-left: 11px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .order-receipt {
    .order-products {
      .cart-item__thumb {
        width: 16%;
      }
      .cart-item__desc-container {
        width: 82%;
        .cart-item {
          &__desc {
            width: 32%;
          }
          &__price {
            width: 28%;
          }
          &__qty {
            width: 10%;
          }
          &__total {
            width: 24%;
          }
        }
      }
    }
  }
}

.promo_thanks_wrapper {
  padding-top: 0;
  max-width: 960px;
  margin: 0 auto;
  padding-bottom: 10px;
  #sidebar {
    padding-top: 10px;
  }
}

#payment-method {
  #payment-container {
    .escrow {
      margin-top: 15px;
    }
  }
}

#colorbox {
  #cboxContent {
    #address_form_container {
      #address {
        .address-form {
          .form_element {
            input[type='text'] + label {
              position: absolute;
            }
          }
          .form_element.full_name_container,
          .form_element.address1_container,
          .form_element.address2_container {
            margin-bottom: 15px;
            input[type='text'] {
              margin-bottom: 0px;
            }
          }
          .phone_1_container,
          .phone_2_container {
            span {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

#shipping_panel {
  #checkout_shipping {
    .address-form {
      .postal_code_container {
        .fieldset-note {
          a {
            height: 44px;
            line-height: 3em;
            padding: 0 12px;
          }
        }
      }
      .form_element {
        &.phone_1_container,
        &.phone_2_container {
          width: 60%;
        }
      }
      .phone_1_container {
        label {
          display: inline-block !important;
        }
      }
      .phone_1_container,
      .phone_2_container {
        .label-content {
          display: block;
        }
        .selectBox {
          height: 44px;
          width: 28%;
          line-height: 2;
          margin: 0 3% 10px 0;
        }
        .phone_part.phone1_1,
        .phone_part.phone2_1,
        .phone_part.phone1_2,
        .phone_part.phone2_2 {
          input[type='tel'] {
            width: 30%;
            margin-right: 2%;
          }
        }
        .phone_part.phone1_1,
        .phone_part.phone2_1 {
          margin-right: 1%;
        }
      }
    }
    #delivery-instructions.adpl {
      padding: 0px;
      margin-bottom: 20px;
      input + label {
        margin-top: calc(-3em - 4px);
        margin-bottom: 5px;
      }
    }
    &.adpl {
      input[type='email'],
      input[type='tel'],
      input[type='password'],
      input[type='text'],
      select {
        & + label {
          margin-top: calc(-3em - 1px);
        }
      }
    }
  }
}

#viewcart,
#samples,
#shipping,
#review,
#payment,
.checkout {
  h2.checkout__panel-title {
    font-size: 14px;
  }
}
