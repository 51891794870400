$color-gray-dark2: #bbb;

.forgot-password {
  margin: 0 auto;
  max-width: 1024px;
  &__content {
    text-align: left;
    margin: 20px 20px 20px 50px;
  }
  &__header {
    font-size: 35px;
    line-height: 50px;
    margin: 0 30px 20px 0;
  }
  .sent-info {
    &__text {
      margin-bottom: 0.8em;
    }
  }
  .password-reset {
    &__item {
      margin-bottom: 1em;
      input {
        width: 33.33%;
        display: block;
      }
    }
    &__text,
    &__form {
      margin-bottom: 0.8em;
    }
  }
  .account-utilities {
    &__header {
      margin: 0 0 10px 0;
      padding-bottom: 16px;
      font-size: 30px;
    }
  }
}

.email_promotions {
  .promotions_label {
    display: inline-block;
    width: 20%;
    #emailpromo-info-panel & {
      width: 25%;
    }
  }
  span {
    margin-right: 20px;
  }
}

.account {
  &__section {
    .account-page {
      &__content {
        float: left;
        width: 75%;
        .section-header__header {
          @include krfont-bold();
        }
        .optional-info__header {
          @include krfont-bold();
        }
        .account-payment {
          .section-content {
            h4 {
              @include krfont-bold();
            }
          }
        }
        .account-address {
          .section-content {
            h4 {
              @include krfont-bold();
            }
          }
        }
      }
      &__header {
        @include krfont-bold();
      }
    }
    .sidebar-right {
      float: right;
    }
    .continue-buttons {
      float: right;
    }
    .info-text {
      margin: 10px 0;
    }
    .profile-info__item {
      margin-left: 0;
    }
    #registration-wrapper {
      #address_form_container {
        border: 0;
        padding-left: 0;
      }
    }
    .optional-info {
      padding-bottom: 20px;
      .gender_container {
        margin-left: 10px;
        label,
        span {
          margin-right: 10px;
        }
      }
      &__birthday-select {
        .selectBox-arrow {
          margin-#{$rdirection}: 0;
        }
      }
    }
    .error_messages.error li {
      margin-left: 0px;
    }
    &.address-book-page {
      .address-book-page__header {
        @include krfont-bold();
      }
      .payment-info__header {
        @include krfont-bold();
      }
      .address-book__header {
        @include krfont-bold();
      }
    }
    &.account-page {
      .profile-info__header,
      optional-info__header {
        @include krfont-bold();
      }
    }
    &.past-purchases-page {
      .past-purchases-page__header {
        @include krfont-bold();
      }
      .past-purchases-data-header__item {
        @include krfont-bold();
      }
    }
    &.favorites-page {
      .favorites-page__header {
        @include krfont-bold();
      }
    }
    .profile-page {
      &__content {
        .optional-info__header,
        .newsletter-info__header {
          @include krfont-bold();
        }
        .profile-page__header {
          @include krfont-bold();
          margin: 4px 0 17px 0;
          padding-bottom: 16px;
          border-bottom: 2px solid $color-black;
        }
        .profile-info__header {
          @include krfont-bold();
        }
      }
      &__title_msg {
        border-bottom: 0;
        margin: 0;
        padding-bottom: 0;
      }
    }
    .account-utilities {
      .sidebar-menu {
        &__item {
          &--signout {
            a {
              display: contents;
            }
          }
        }
      }
    }
  }
  &__page-header {
    padding-bottom: 20px;
    border-bottom: 1px solid $color-black;
    margin-bottom: 10px;
  }
  .account-profile {
    .account-profile__name {
      span {
        @include krfont-bold();
      }
    }
    .account-profile__email {
      span {
        @include krfont-bold();
      }
    }
    .account-profile__newsletter {
      span {
        @include krfont-bold();
      }
    }
    .account-profile__newsletter-mobile {
      span {
        @include krfont-bold();
      }
    }
  }
  #cboxClose {
    top: 10px;
    right: 5px;
  }
}

.order-details-page {
  &__header {
    @include krfont-bold();
  }
}

.cart-header {
  @include krfont-bold();
}

.profile-form-container {
  .legal_statement {
    border: 1px solid $color-light-gray;
    width: 400px;
    height: 100px;
    overflow-y: scroll;
    padding: 5px;
  }
}

.address {
  .postal_code_container {
    position: relative;
    padding-bottom: 10px;
    span {
      &.fieldset-note {
        position: absolute;
        top: 0;
        left: 100%;
        width: 100%;
        padding-left: 20px;
      }
    }
  }
  .form_element {
    &.phone_1_container,
    &.phone_2_container {
      .selectBox {
        width: 36.5%;
        height: 32px;
        vertical-align: top;
      }
      input {
        &.field {
          width: 30%;
          vertical-align: top;
        }
      }
      .selectBox-dropdown {
        display: inline-block !important;
        margin: 5px 15px 10px 0;
      }
    }
  }
  .address-form__item label {
    color: $color-black;
    border: none;
  }
}

.order-details__item {
  h4 {
    font-weight: bold;
  }
}

.address-container {
  font-style: normal;
}

#colorbox {
  #cboxLoadedContent {
    overflow-x: hidden !important;
    min-height: 585px;
    #address-lookup-container {
      .error {
        color: $color-red;
      }
      .address-search {
        .town-submit-btn {
          line-height: 1.5;
          height: 40px;
        }
      }
    }
  }
  #address_form_container {
    padding: 0px 20px 20px 0px;
    position: relative;
    .address-form {
      &__header {
        margin: 0px 0px 11px 0px;
      }
      &__item {
        float: left;
        width: 100%;
        padding: 10px 0px;
        .input_postal_code {
          width: 77.5%;
        }
        .fieldset-note {
          position: relative;
          left: 0px;
          top: 0px;
          width: auto;
          .address_lookup_submit.button {
            padding: 0.6em 30px !important;
          }
        }
        .phone_part_area_code,
        .phone_part {
          width: 32%;
          margin-left: 2%;
          float: left;
          select,
          input[type='text'] {
            width: 100%;
            height: 32px;
          }
        }
        .phone_part_area_code {
          margin-left: 0px;
        }
      }
      .address-form__header {
        @include krfont-bold();
      }
      .town-submit-btn.button {
        line-height: 0px;
      }
      #submit-btn {
        margin-top: 5px;
        padding: 0px 30px;
      }
      .form_element {
        &.phone_1_container,
        &.phone_2_container {
          .selectBox {
            width: 100%;
          }
        }
      }
    }
  }
  #cboxWrapper {
    #escrow_popup_content {
      h2 {
        font-size: 36px;
        line-height: 38px;
        color: $color-black;
        font-weight: normal;
        margin-bottom: 20px !important;
      }
    }
  }
}

.order_total {
  float: left;
  margin-right: 23px;
}

.order {
  &-return-page,
  &-cancel-page {
    .account-utilities {
      float: left;
      margin-right: 32px;
    }
    #return-details {
      input,
      .selectBox-dropdown {
        width: 50%;
      }
    }
    .order-totals {
      width: 29%;
      float: right;
      th,
      td {
        border: 0;
      }
    }
  }
}

#product-info-wrapper {
  &.checkout__content {
    width: 100%;
    .view-cart-products {
      border-bottom: 1px solid $color-light-gray;
    }
  }
}

.favorites__recommended-products {
  .product_name {
    min-height: 75px;
  }
  .formatted_price {
    min-height: 60px;
  }
}

.account__section {
  .favorites-page__content {
    .favorites-page__tabs {
      right: 0px;
    }
    .my-lists__list-link {
      &--print {
        vertical-align: top;
      }
      &--email {
        vertical-align: top;
      }
      &--share {
        vertical-align: top;
      }
    }
  }
}

.order-details {
  .cart-item__desc {
    width: 270px;
  }
  .cart-header {
    .qty {
      width: 22%;
    }
  }
  .cart-header-spacer {
    width: 40%;
  }
  .cart-item__desc-container {
    width: 86%;
  }
  .cart-item {
    &__qty {
      width: 12%;
    }
    &__total {
      width: 16%;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .favorites__recommended-products {
    .product_name {
      min-height: 120px;
    }
    .formatted_price {
      min-height: 70px;
    }
  }
  .order-details {
    .cart-item__desc-container {
      width: 81%;
      .cart-item {
        &__desc {
          width: 30%;
        }
        &__price {
          width: 30%;
        }
        &__total {
          width: 23%;
        }
        &__qty {
          width: 8%;
        }
      }
    }
    .cart-item__thumb {
      width: 16%;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .account__section {
    .account-page__content {
      float: none;
      width: auto;
    }
  }
}

.sign-in-page__container {
  .account__return-user,
  .account__new-account {
    @include breakpoint($medium-up) {
      margin-bottom: 20px;
      fieldset {
        .form_input,
        .form_label {
          input[type='email'],
          input[type='password'] {
            margin-bottom: 5px;
          }
        }
      }
    }
    .section-head {
      h2.section-head__header {
        border-bottom: none !important;
        float: left;
        font-weight: bold;
        font-size: 30px;
      }
      span.section-head__link {
        float: left;
        padding-left: 10px;
        height: 50px;
        line-height: 50px !important;
      }
      .sign-in-page__indent {
        float: left;
      }
    }
    .form_input {
      width: 40%;
      float: left;
    }
  }
  .account__new-account,
  .account__return-user {
    width: 100% !important;
    min-height: 250px;
  }
  .account__new-account {
    .form-item {
      margin: 0 0 25px;
      width: 360px;
      &.pc_email_promo,
      &.sms_promo,
      &.postal_promo {
        margin: 0 0 7px;
      }
    }
    .new-account__fieldset {
      .password {
        width: 720px !important;
        clear: both;
        input[type='text'],
        input[type='password'] {
          display: inline-block;
        }
        .pwd_msg {
          font-weight: bold;
          color: $color-red;
        }
      }
      .full_name {
        input[type='text'] + label {
          float: left;
        }
      }
      .optional-info {
        &__header {
          font-size: 15px;
          font-weight: bold;
        }
        &__item {
          span {
            margin-right: 0;
          }
        }
        &__fieldset {
          margin-left: 0;
          .gender_container {
            margin-top: 10px;
            margin-left: 0;
            label {
              margin-right: 0;
            }
          }
        }
      }
    }
    .mobile_number_field {
      width: 600px;
      .form_label {
        width: 17% !important;
      }
      .form_input {
        display: inline-block;
        .form_mobile_input {
          margin-left: 5px;
          input {
            width: 72px;
            display: inline-block;
          }
        }
        .form_select {
          select {
            height: 32px;
            padding: 5px 10px;
            width: 79px;
          }
          a {
            float: left;
            width: 75px;
          }
        }
        a#sms_request_pin_tout {
          margin-left: 10px;
        }
      }
    }
    .mobile_pin_field {
      width: 600px;
      .form_label {
        width: 17% !important;
      }
      input[type='text'] {
        width: 253px;
        display: inline-block;
      }
    }
  }
  .return-user__item,
  .new-account__fieldset {
    clear: both;
    .form_label {
      float: left;
      width: 28%;
      padding-top: 5px;
    }
    .form-item {
      &.mobile_number_field {
        .form_label {
          float: none;
          margin-bottom: 5px;
        }
      }
    }
    input {
      margin: 0 0 3px;
      width: 253px;
    }
    .return-user-email {
      float: none;
    }
    .show {
      padding: 5px 10px 5px 0;
      display: block;
      clear: both;
      margin-left: 14%;
    }
  }
  .return-user__item {
    .form_input {
      input[type='email'],
      input[type='password'] {
        width: 100%;
      }
    }
    span.forgot-pw {
      margin-top: 20px;
      display: inline-block;
    }
    .show {
      display: inline-block;
      float: left;
      margin-left: 28%;
    }
  }
}

.full_name,
.email {
  float: left;
}

div.legal_statement {
  border: 1px solid $color-gray-dark2;
  height: 87px;
  line-height: 18px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 5px;
}

div.radio_group,
.group_label {
  margin: 2px 0;
}

.terms_agree {
  .terms {
    margin-top: 14px;
  }
  .form-block {
    margin-bottom: 14px;
  }
  .label.error {
    color: $color-red;
  }
}

.promo_label {
  display: inline;
  margin-right: 7px;
}

.button {
  padding: 0.6em 0.5em;
}

.sign-in-page {
  .sign-in-page__indent {
    .new-account__fieldset {
      .password {
        .form_label {
          width: 14% !important;
        }
      }
      .all_term_accept {
        margin: 0 0 5px;
        width: 580px;
      }
      .age_restriction {
        width: 700px;
        .label.error {
          color: $color-red;
        }
      }
    }
  }
  .label-content {
    font-weight: bold;
  }
  .sign-in-page__container {
    .return-user__item,
    .new-account__fieldset {
      .show {
        margin-left: 0px;
      }
      input[type='text'],
      input[type='password'],
      input[type='email'],
      input[type='tel'],
      select {
        border-color: $color-black;
        &:focus {
          border-color: $color-pink;
        }
        &.error {
          border-color: $color-red;
        }
      }
    }
    .account__new-account {
      .form_input {
        width: 90%;
      }
      .mobile_pin_field {
        display: flex;
        align-items: center;
        .form_label {
          flex: 0.9;
        }
        .form_input {
          flex: 1;
        }
      }
      .mobile_number_field {
        .form_select {
          a.selectBox {
            height: 44px;
            border-color: $color-black;
            &.error {
              border-color: $color-red;
            }
            .selectBox-label,
            .selectBox-arrow {
              line-height: 3em;
            }
          }
        }
      }
      a#sms_request_pin {
        margin-left: 10px;
      }
    }
  }
  @include breakpoint($medium-up) {
    input[type='submit'].sign-in-page__submit {
      position: relative !important;
      left: 0px !important;
    }
  }
}

.optional-info {
  .optional-info__birthday-label {
    margin: 0px 0px 10px 0px;
  }
  .optional-info__item {
    padding: 0px;
    span {
      margin-right: 20px;
    }
  }
  .optional-info__birthday-select {
    min-width: 110px;
    margin: 0px;
    a.selectBox,
    a.selectbox {
      width: 100px;
    }
  }
  &__fieldset {
    margin-left: 20px;
  }
}

.profile-page__content {
  .withdrawal-link {
    margin-left: 20px;
  }
  #profile_preferences {
    .profile-info {
      .full_name_container {
        margin-bottom: 20px;
        input[type='text'] {
          margin-bottom: 0px;
        }
      }
      #sms_request_pin,
      #sms_verify_pin {
        height: 44px;
        line-height: 2em;
      }
      .mobile_number {
        a.selectBox {
          height: 44px;
          border-color: $color-black;
          &.error {
            border-color: $color-red;
          }
        }
        .label-content {
          margin-top: 12px;
        }
        .mobile_number_space {
          margin-top: 10px;
        }
      }
      .selectBox-label,
      .selectBox-arrow {
        line-height: 3em;
      }
    }
    &.adpl {
      input[type='email'],
      input[type='tel'],
      input[type='password'],
      input[type='text'],
      select {
        border-color: $color-black;
        &:focus {
          border-color: $color-pink;
        }
        &.error {
          border-color: $color-red;
        }
        & + label {
          margin-top: calc(-3em - 1px);
        }
      }
    }
  }
}

.profile-info {
  margin-bottom: 20px;
  .full_name_container {
    span.label-content {
      width: 110px;
      float: left;
    }
    span.required_mark {
      float: left;
    }
    .field {
      width: 220px;
      margin-bottom: 20px;
    }
  }
  #sms_request_pin {
    margin-bottom: 10px;
    float: left;
  }
  #sms_request_msg {
    margin: 5px;
  }
  .profile-info__item {
    span.label-content {
      width: 110px;
      float: left;
    }
    .field {
      width: 220px;
    }
    span.required_mark {
      float: left;
    }
  }
  .mobile_number {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    .form_label {
      float: left;
      display: block;
      width: 100%;
      padding: 0;
      margin-bottom: 5px;
    }
    span {
      float: left;
      margin-right: 5px;
    }
    select {
      height: 32px;
    }
    a.selectBox,
    a.selectBox {
      width: 73px;
    }
    .form_mobile_input input[type='text'],
    input[type='tel'] {
      width: 57px;
    }
    span.label-content {
      width: 110px;
      float: left;
      margin-right: 0px;
    }
    span.required_mark {
      margin-right: 0px;
    }
    a.sms_request_pin {
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
  .mobile_verify {
    .form_label {
      width: 117px;
      float: left;
    }
    input[type='text'] {
      width: 102px;
    }
  }
}

#account_profile {
  #content {
    fieldset.fs {
      div.form_element label {
        width: 85px;
      }
    }
  }
}

#address_form_container {
  border: 0;
  padding: 0;
  .address {
    .form_label {
      padding: 10px 0;
    }
    input {
      width: 100%;
    }
    .address_lookup_submit button {
      margin-top: 10px;
    }
    .form_element {
      width: 50%;
      .form_element_item {
        display: inline-block;
        width: 50%;
      }
      input[type='text'],
      input[type='email'],
      input[type='password'],
      input[type='tel'] {
        margin-bottom: 5px;
      }
      input,
      select {
        width: 100%;
        border-color: $color-black;
        &:focus {
          border-color: $color-pink;
        }
        &.error {
          border-color: $color-red;
        }
      }
      &.phone_1_container,
      &.phone_2_container {
        input.field {
          width: 30%;
          margin-right: 10px;
        }
        select {
          & + a.selectBox {
            border-color: $color-black;
            height: 44px;
            margin-top: 0;
            margin-right: 8px;
            line-height: 2.75;
            width: 30%;
            &-active {
              border-color: $color-pink;
            }
            .selectBox-arrow {
              line-height: 2.75;
            }
            &.error {
              border-color: $color-red;
            }
          }
        }
      }
    }
    #profile-postal {
      width: 100%;
      a.address_lookup_submit {
        padding: 15px;
        margin: 0 10px;
      }
    }
  }
  #continiue-btn {
    height: 40px;
    width: 75px;
  }
}

.spp {
  .full_sign_in {
    .form {
      .row input[type='submit'] {
        height: 32px;
        line-height: 16px;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) {
  .past-purchases__content {
    .past-purchases-data-item {
      li.created-date {
        width: 18%;
      }
      li.cancel-order {
        width: 13%;
      }
      li.view-details {
        width: 5%;
        float: right;
      }
    }
  }
  .past-purchases-data-header {
    li.created-date {
      width: 18%;
    }
    li.order-status {
      width: 22%;
    }
    li.cancel-order {
      width: 20%;
    }
  }
}
