/* font-style : ³ª´®°íµñ */
@font-face {
  font-family: 'Nanum Gothic bold';
  src: url('#{$netstorage-fonts-path}cjk/NanumGothic/NanumGothic-Bold.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Nanum Gothic';
  src: url('#{$netstorage-fonts-path}cjk/NanumGothic/NanumGothic-Regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Nanum Gothic extra bold';
  src: url('#{$netstorage-fonts-path}cjk/NanumGothic/NanumGothic-ExtraBold.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 800;
}

* {
  font-family: 'Nanum Gothic', sans-serif;
}

body,
div,
ul,
li,
a,
p,
span,
table,
tr,
th,
td,
h1,
h2,
h3,
h4,
h5,
input,
form,
dl,
dt,
dd,
label,
small,
select,
article,
strong,
h10,
.site-nav,
.button {
  font-family: 'Nanum Gothic', sans-serif !important;
}

.spp {
  .spp-page__breadcrumb {
    font-size: 14px;
    a {
      color: #888;
      font-family: 'Nanum Gothic', sans-serif !important;
    }
  }
  .responsive-tabs--how_to_use-wrap {
    .resp-tabs-list {
      margin-bottom: 23px;
    }
  }
  .BVRRRootElement a {
    font-size: 14px;
    color: #000;
  }
  #BVRRSummaryContainer {
    margin-top: 6px;
  }
  .button {
    font-size: 16px;
    padding: 0.5em !important;
  }
  .product--full {
    .product__promo {
      padding: 7px 11px;
      line-height: 1.7em;
    }
    .product__title-link {
      font-weight: bold !important;
    }
  }
  .responsive-tabs {
    .resp-tab-content {
      padding: 0 20px 22px 20px;
    }
    .resp-tab-item {
      padding: 8px 20px;
      letter-spacing: 4px;
      margin-bottom: 0px;
    }
  }
  .product-thumb--price {
    margin: 12px 0 11px 0;
  }
  .BVRRDisplayContentLinkWrite a,
  .BVRRDisplayContentLinkWrite a:visited {
    padding: 5px 0;
  }
  .BVRRRatingNormalImage {
    margin-bottom: 3px;
  }
  .BVRRReviewTitle {
    color: #000;
    font-family: 'Nanum Gothic', sans-serif !important;
    font-size: 18px;
    font-weight: 700;
  }
  .BVRRReviewTitleContainer {
    margin: 0 0 17px;
  }
  .BVRRRecommendedContainerYes {
    padding-left: 21px;
  }
  .BVRRRecommendedContainer {
    font-size: 13px !important;
    margin-bottom: 33px !important;
  }
  .BVRRReviewDisplayStyle5Text {
    margin-bottom: 44px;
  }
  .BVDI_FV {
    .BVDI_FVVote a,
    .BVDI_FV .BVDI_FVVote span {
      letter-spacing: 0;
      color: #000000;
    }
  }
  .BVDI_FVVotes {
    .BVDIPrefix {
      line-height: 3em;
    }
  }
  .BVRRPageNumber,
  .BVRRPageLink {
    padding: 5px 9px;
  }
  .BVRRUserNickname,
  .BVRRUserNickname a {
    font-size: 14px;
    font-weight: bold;
  }
  .BVRRDisplayContentReview {
    padding: 36px 0;
  }
  .BVDI_BAItemContributor {
    a.BVDILink {
      padding-left: 29px;
    }
  }
  .BVDI_BA {
    margin-bottom: 16px;
  }
  .responsive-tabs--bazaarvoice {
    .resp-tab-content {
      padding: 0;
    }
  }
  .BVRRContextDataContainer {
    font-size: 13px;
    letter-spacing: 0.5px;
  }
  .BVRRContextDataValueContainer {
    line-height: 1.6em;
    margin-right: 0;
  }
  @media (min-width: 768px) {
    .responsive-tabs--how_to_use-wrap {
      margin: 22px auto;
    }
    .section-header {
      font-size: 15px;
      font-weight: 700;
    }
    .product-thumb__headline-link {
      font-weight: 700;
    }
  }
  .site-header__addtobag h8 {
    font-weight: bold !important;
  }
  .product-thumb--price {
    font-weight: bold !important;
  }
  .site-footer-contact {
    span.icon-email {
      font-weight: bold !important;
    }
    span.icon-phone {
      font-weight: bold !important;
    }
    .site-footer__column {
      h3.site-footer-social-links__header {
        font-weight: bold !important;
      }
    }
  }
  .site-header__addtobag__product-info h8 {
    font-weight: bold;
  }
  .js-product__info product__info {
    .product__price {
      font-weight: bold;
    }
  }
}

.site-footer-social-links__header,
.site-footer-contact__link,
.site-header__addtobag__product-name,
.site-header__addtobag__product-price,
.site-footer-contact__item--phone,
.site-footer-contact__item--email {
  font-weight: bold !important;
}

.input[type='submit'],
.account-overlay .account-overlay__next .account-overlay__next-trigger,
.email-overlay .account-overlay__next .account-overlay__next-trigger,
input[type='button'],
button,
.offers-vip__button {
  font-family: 'Nanum Gothic', sans-serif !important;
}

.site-nav .menu__link--lvl-1,
.site-nav .menu__link--lvl-2,
.site-footer-contact__link,
.site-footer-social-links__header,
.sticky-offer__headline,
.sticky-chat__headline,
.responsive-tabs h2.resp-accordion,
.responsive-tabs h2.resp-tab-active,
.product--full .product__sku-categories-nav-item,
.product-gallery__shade-name,
.quickshop .product--full .product__title,
.quickshop .product--full .product__rating,
.product-filter__item,
.site-footer__main .site-email-signup__header,
.sign-in .account-links__profile,
.sign-in .account-links__orders,
.sign-in .account-links__favorites,
.sign-in .account-links__loyalty,
.sign-in .account-links__login,
.sign-in .account-links__logout,
.mpp__filters__headline,
.spp-mobile-page .spp__share,
.account-profile .section-header__header,
.account-orders .section-header__header,
.account-address .section-header__header,
.account-payment .section-header__header,
.account-loyalty .section-header__header,
.account-mobile-landing-page .account-landing-menu .account-landing-menu__item a,
.address-book-page .address-book__header,
.address-book-page .payment-info__header,
.add-payment-page__header,
.artist-picks-page__products .recommended-products__links-add-all,
.chat-history-page__products .recommended-products__links-add-all,
.artist-picks-page .transcript .transcript__chat--skus .recommended-skus__links-add-all,
.chat-history-page .transcript .transcript__chat--skus .recommended-skus__links-add-all,
.favorites-page__tab,
.wishlist-page__header,
.my-lists__list-header-link,
.address-info__header,
.address-info h3,
.profile-info__header,
.profile-info h3,
.optional-info__header,
.optional-info h3,
.sms-promotions__header,
.sms-promotions h3,
.newsletter-info__header,
.newsletter-info h3,
.h8,
.product-thumb__headline,
.product-thumb--price,
.product-recommendation .product-recommendation__filter-nav > li,
.responsive-tabs .resp-tab-item,
.product--full .product__price,
.product--full .product__rating,
.product--full .product__tip .product__tip-title,
.shade-picker,
.shade-picker__color-name,
.search-form .search-suggestions__link {
  font-family: 'Nanum Gothic', sans-serif !important;
}

[class*='icon-'],
.icon,
.selectBox-arrow:before,
.site-nav .menu__link--lvl-1.menu__link--has-children:after,
.site-nav .menu__link--lvl-2.menu__link--has-children:after,
.site-utils__dropdown__close:after,
#cboxClose:before,
.slick-prev:before,
.slick-next:before,
input[type='checkbox'] ~ label:before,
input[type='checkbox'] ~ .label:before,
input[type='checkbox']:checked ~ label:before,
input[type='checkbox']:checked ~ .label:before,
input[type='radio'] ~ label:before,
input[type='radio'] ~ .label:before,
input[type='radio']:checked ~ label:before,
input[type='radio']:checked ~ .label:before,
.bobbis-kit .product-grid__item:nth-child(1):after,
.bobbis-kit .product-grid__item:nth-child(2):after,
.responsive-tabs.responsive-tabs--how_to_use .resp-arrow:before,
.quickshop-wrapper.active:before,
.quickshop__close:before,
.foundation-finder__find-match:after,
.foundation-finder__hide:after,
.foundation-finder__quiz:before,
.foundation-finder__chat__copy:before,
.product-palette:before,
.product-sku-quickview__inner:before,
.offer-specific__container .offer__breadcrumb-link:before,
.makeup-lesson__step .icon-arrow:before,
.artist-picks-page__products .recommended-actions a:before,
.chat-history-page__products .recommended-actions a:before,
.favorites-mylists__lists-create-button:before,
.favorite-actions__link--add:before,
.favorite-actions__link--remove:before,
.my-lists__list-link--print:before,
.my-lists__list-link--email:before,
.giftcard__guides-header:before {
  font-family: 'icons' !important;
}

button,
.offers-vip__button,
input[type='submit'],
.account-overlay .account-overlay__next .account-overlay__next-trigger,
.email-overlay .account-overlay__next .account-overlay__next-trigger,
.button,
.viewcart-buttons .go-shopping,
.recommended-item__button,
.sign-in .login__register,
.sign-in .registration__login,
.button--inverted,
.offers-hero__button,
.offers-details-item__button,
.offer-specific__container .offer-hero__button,
.viewcart-buttons-panel a.continue-checkout,
.viewcart-buttons-panel a.gwp_continue-checkout,
.viewcart-buttons a.continue-checkout,
.address-book-page .default-address__button,
.address-book-page .default-payment__button,
.artist-picks-page__no-chats .no-transcripts__link,
.chat-history-page__no-chats .no-transcripts__link,
.button--secondary,
.button--underline,
.button--outline-thin,
.offer-specific__container .offer__category-button,
.makeup-lesson-page__video .bobbis-lessons .link--get-the-look,
.button--outline,
.checkout a.btn,
.checkout .offer-code-panel input[type='submit'].btn,
.checkout .offer-code-panel .account-overlay .account-overlay__next .btn.account-overlay__next-trigger,
.account-overlay .account-overlay__next .checkout .offer-code-panel .btn.account-overlay__next-trigger,
.checkout .offer-code-panel .email-overlay .account-overlay__next .btn.account-overlay__next-trigger,
.email-overlay .account-overlay__next .checkout .offer-code-panel .btn.account-overlay__next-trigger,
.promo-bca-panel input[type='submit'].promo_bca_button,
.promo-bca-panel .account-overlay .account-overlay__next .promo_bca_button.account-overlay__next-trigger,
.account-overlay .account-overlay__next .promo-bca-panel .promo_bca_button.account-overlay__next-trigger,
.promo-bca-panel .email-overlay .account-overlay__next .promo_bca_button.account-overlay__next-trigger,
.email-overlay .account-overlay__next .promo-bca-panel .promo_bca_button.account-overlay__next-trigger,
.checkout__sidebar .product .sample-select-button,
.samples-panel .product .sample-select-button,
.samples-page .product .sample-select-button,
.address-book-page .address-book__link,
.address-book-page .payment-info__link,
.payment-address__link {
  font-family: 'Nanum Gothic', sans-serif !important;
}

.mpp {
  .product__flag {
    margin-bottom: 1px;
  }
  .product-thumb--price {
    margin: 10px 0 10px 0;
  }
  .site-utils__inner {
    padding: 0 18px 0 30px;
  }
  .site-utils__promo .utility-promo {
    font-size: 12px;
  }
  .node-elc-mpp .page-header__title__inner,
  .mpp .page-header__title__inner {
    font-weight: 800;
  }
  .page-header__text-wrapper {
    padding: 20px 0 23px;
  }
  @media (min-width: 768px) {
    .page-header__title--small {
      font-size: 15px !important;
      font-weight: bold;
    }
    .page-header__subtitle {
      margin-top: 20px !important;
      font-size: 15px !important;
      font-weight: bold;
    }
  }
  @media (min-width: 768px) {
    .product-thumb__headline-link {
      font-weight: 700;
    }
  }
  @media (min-width: 768px) {
    .product-thumb.active .product-thumb__cta,
    .no-touch .product-thumb:hover .product-thumb__cta {
      font-size: 12px;
    }
  }
  .site-nav .menu__link--lvl-1,
  .site-nav .menu__link--lvl-2,
  .site-footer-contact__link,
  .site-footer-social-links__header,
  .sticky-offer__headline,
  .sticky-chat__headline,
  .responsive-tabs h2.resp-accordion,
  .responsive-tabs h2.resp-tab-active,
  .product--full .product__sku-categories-nav-item,
  .product-gallery__shade-name,
  .quickshop .product--full .product__title,
  .quickshop .product--full .product__rating,
  .product-filter__item,
  .site-footer__main .site-email-signup__header,
  .sign-in .account-links__profile,
  .sign-in .account-links__orders,
  .sign-in .account-links__favorites,
  .sign-in .account-links__loyalty,
  .sign-in .account-links__login,
  .sign-in .account-links__logout,
  .mpp__filters__headline,
  .spp-mobile-page .spp__share,
  .account-profile .section-header__header,
  .account-orders .section-header__header,
  .account-address .section-header__header,
  .account-payment .section-header__header,
  .account-loyalty .section-header__header,
  .account-mobile-landing-page .account-landing-menu .account-landing-menu__item a,
  .address-book-page .address-book__header,
  .address-book-page .payment-info__header,
  .add-payment-page__header,
  .artist-picks-page__products .recommended-products__links-add-all,
  .chat-history-page__products .recommended-products__links-add-all,
  .artist-picks-page .transcript .transcript__chat--skus .recommended-skus__links-add-all,
  .chat-history-page .transcript .transcript__chat--skus .recommended-skus__links-add-all,
  .favorites-page__tab,
  .wishlist-page__header,
  .my-lists__list-header-link,
  .address-info__header,
  .address-info h3,
  .profile-info__header,
  .profile-info h3,
  .optional-info__header,
  .optional-info h3,
  .sms-promotions__header,
  .sms-promotions h3,
  .newsletter-info__header,
  .newsletter-info h3,
  .h8,
  .product-thumb__headline,
  .product-thumb--price,
  .product-recommendation .product-recommendation__filter-nav > li,
  .responsive-tabs .resp-tab-item,
  .product--full .product__price,
  .product--full .product__rating,
  .product--full .product__tip .product__tip-title,
  .shade-picker,
  .shade-picker__color-name,
  .search-form .search-suggestions__link {
    font-size: 14px;
    font-weight: 700;
  }
  .sticky-offer__headline {
    font-size: 16px;
    font-weight: 700;
  }
  .site-utils__promo,
  .site-footer,
  .site-footer__column:nth-child(2) .menu__item,
  .locale-select__link,
  .language-select__link,
  .tooltipster-default .tooltipster-content,
  .product-gallery__shade-description,
  .site-utils__dropdown .site-email-signup__header,
  .sign-in .login__header,
  .sign-in .login__no-account,
  .sign-in .registration__header,
  .sign-in .registration__have-account,
  .sign-in .account-links__welcome,
  .mpp__filters__summary,
  .mpp__filters .product-filter__label,
  .h9,
  .product--full .product__weight,
  .product--full .product__unit-price,
  .search-form .product__abstract,
  .search-form .search-related-story__description,
  .search-form--results .search-form__results-count,
  .live-chat__box,
  .spp .spp-page__breadcrumb a {
    font-size: 13px;
    font-weight: 700;
  }
  .site-footer {
    font-size: 11px;
    font-weight: normal;
  }
  .sticky-offer,
  .sticky-chat {
    padding: 15px 16px;
  }
  @media (min-width: 768px) {
    .sticky-offer {
      width: 188px;
    }
  }
  .site-footer-social-links__header,
  .site-footer-contact__link,
  site-header__addtobag__product-name,
  site-header__addtobag__product-price {
    font-weight: bold !important;
  }
}
